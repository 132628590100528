@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  margin-top: 28px;
}

.controlsHolder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin: -30px 0 0;
  width: 100%;
}

.inputBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 8px 0;
}

.inputWarningBlock {
  margin-top: -10px;
}

.roundButtonHolder {
  display: flex;
  align-items: center;
  height: 0;
  margin-top: -80px;
  gap: 16px;
  position: sticky;
  bottom: 0;

  button {
    right: 15px;
    bottom: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    transition: 0.2s;
    height: 54px;
    width: 54px;

    &:hover {
      opacity: 0.9;
    }

    span {
      color: #fff;
      font-size: 32px;
      line-height: 32px;
    }
  }
}

.roundButton {
  height: 44px;
  width: 44px;
  border: none;
  outline: none;
  cursor: pointer;
  background: $blue;
  border-radius: 100%;
  box-shadow: 0px 5px 10px -5px $grey;
  transition: all 0.3s;

  svg {
    fill: $white;
    height: 24px;
    width: 24px;
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
    background-color: $grey-2;
  }
}

.submitButton {
  margin: 0 16px;

  &:disabled {
    opacity: 0;
  }
}

.submitWarning {
  margin-top: -20px;
}

.formError {
  @include textMedium;
  color: $red;
}

.root :global(table) {
  table-layout: fixed;
  margin-top: 10px;
}

.root :global {
  td {
    text-align: left;
    padding: 0 10px;
  }

  td:nth-child(1) {
    text-align: center;
    padding: 0;
  }

  input {
    height: 32px;
    font-size: 12px;

    &:disabled {
      pointer-events: none;
      border: transparent;
      background: 0;
    }
  }

  th:nth-child(4) {
    svg {
      display: none;
    }
  }

  td:nth-child(2),
  td:nth-child(3) {
    overflow: hidden;
    white-space: nowrap;

    .truncate {
      display: block;
      padding: 5px 10px 5px 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.actionCell {
  svg {
    fill: $green;
    height: 20px;
    cursor: pointer;
  }
}

.warning {
  text-align: left;
  color: $orange;
  font-size: 10px;
  margin: -5px 0 5px 0;
}
